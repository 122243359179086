<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="show_modal"
    :click-to-close="false"
  >
    <!-- layer :  추천 상품과 연결된 '노출항목' -->
    <section id="modalResultItem" class="layer_dialog">
      <h2 class="mb20">추천 상품 - 결과화면 노출항목 불러오기</h2>

      <div class="mb10">
        <select
          v-model="select_product_type"
          style="width: 100%"
          @change="selectProductType()"
        >
          <option value="none">설정한 추천 상품 선택</option>
          <option value="choice" v-show="is_show_choice">
            사용자 선택에 따른 순위별 상품 선택
          </option>
        </select>
      </div>

      <div class="mb10">
        <select
          v-if="select_product_type == 'none'"
          v-model="select_product"
          style="width: 100%"
        >
          <option value="">선택</option>
          <option
            v-for="(product, key) of this.product_list"
            :key="key"
            :value="product.product_no"
          >
            {{ optionTextLength(product.product_name, 25) }}
          </option>
        </select>
        <select v-else v-model="rank" style="width: 100%">
          <option value="">선택</option>
          <option
            v-for="(product, key) of this.product_list"
            :key="key"
            :value="key + 1"
          >
            {{ key + 1 }}위
          </option>
        </select>
      </div>

      <select name="" v-model="select_item" style="width: 574px">
        <option value="" disabled>선택</option>
        <option v-for="(item, key) of item_list" :value="item" :key="key">
          [{{ item.type === 'text' ? '텍스트' : '이미지' }}]
          {{ optionTextLength(item.title, 16) }}
        </option>
      </select>
      <button
        type="button"
        @click="addItem"
        class="btn btn_large btn_light ml5"
      >
        추가
      </button>

      <ul class="item_list">
        <li v-for="(item, key) of this.select_item_list" :key="key">
          <button type="button" @click="deleteItem(key)" name="">
            <span class="material-icons">cancel</span>
          </button>
          [{{ item.type === 'text' ? '텍스트' : '이미지' }}]
          {{ optionTextLength(item.title, 16) }}
        </li>
      </ul>

      <div class="btns">
        <ul>
          <li>
            <button
              type="button"
              @click="close"
              class="btn btn_large btn_secondary"
            >
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              @click="setResult"
              class="btn btn_large btn_primary"
            >
              <span>저장</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 추천 상품과 연결된 '노출항목' -->
  </vue-final-modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  inheritAttrs: false,
  data: () => ({
    show_modal: true,
    item_list: [],
    select_item: '',
    select_item_list: [],
    recommend_select_item_list: [],
    select_product: '',
    product_list: [],
    select_product_type: 'none',
    is_show_choice: true,
    rank: '',
  }),
  props: [
    'item',
    'type',
    'result_desktop_items',
    'result_mobile_items',
    'survey_config_info',
  ],
  created: async function () {
    await this.init();
  },
  computed: {
    ...mapState('qna_product', ['products']),
  },
  mounted() {},
  methods: {
    init: async function () {
      if (this.survey_config_info.question_type == 'none') {
        this.is_show_choice = false;
      }

      // 등록된 상품 전부를 가져온다.
      // 5000개 까지만..
      await this.$store.dispatch('qna_product/getProducts', {
        offset: 0,
        limit: 5000,
      });

      this.item_list = JSON.parse(this.survey_config_info.result_item);

      if (this.item !== null && this.item !== undefined) {
        this.select_item_list = this.item.show_item.show_items;

        if (this.item.show_item.select_product !== undefined) {
          this.select_product = this.item.show_item.select_product;
        }
      }

      let result = [];

      if (this.type === 'desktop') {
        result = this.result_desktop_items;
      } else {
        result = this.result_mobile_items;
      }
      for (const product of this.products['list']) {
        let param = product;
        let _product_name = '';
        if (product.mall_product.display == 'F') {
          _product_name += '[미진열]';
        }
        if (product.mall_product.selling == 'F') {
          _product_name += '[판매안함]';
        }
        if (_product_name.length > 0) {
          _product_name = _product_name + ' ' + product.product_name;
        } else {
          _product_name = product.product_name;
        }

        param.product_name = _product_name;

        this.product_list.push(param);
      }
      if (!this.item) {
        return;
      }

      this.select_item_list = [];

      if (this.item.show_item && this.item.show_item.type == 'show_items') {
        if (this.item.show_item.select_product_type == 'none') {
          this.select_product = this.item.show_item.select_product;
          this.select_product_type = this.item.show_item.select_product_type;

          for (const show_item of this.item.show_item.show_items) {
            this.select_item_list.push(show_item);
          }
          this.rank = '';
        } else {
          this.select_product = '';
          this.select_product_type = this.item.show_item.select_product_type;
          for (const show_item of this.item.show_item.show_items) {
            this.select_item_list.push(show_item);
          }
          this.rank = this.item.show_item.rank;
        }
      }

      if (
        this.product_list.length == 0 ||
        this.product_list.length < this.rank
      ) {
        this.rank = '';
      }
    },
    setResult: function () {
      if (this.select_product === '' && this.rank === '') {
        alert('추천 상품을 선택해주세요.');
        return false;
      } else if (
        (this.select_item === '' || this.select_item === {}) &&
        this.select_item_list.length === 0
      ) {
        alert('노출 항목을 선택해주세요.');
        return false;
      } else if (this.select_item_list.length === 0) {
        alert('결과 화면 노출 항목을 추가해주세요.');
        return false;
      }

      let params = {};
      if (this.select_product_type == 'none') {
        params = {
          type: 'show_items',
          select_product_type: this.select_product_type,
          select_product: this.select_product,
          show_items: this.select_item_list,
          rank: '',
        };
      } else {
        params = {
          type: 'show_items',
          select_product_type: this.select_product_type,
          select_product: this.select_product,
          show_items: this.select_item_list,
          rank: this.rank,
        };
      }

      if (this.item !== null) {
        if (this.type === 'desktop') {
          this.result_desktop_items.splice(this.item.index, 1, params);
        } else {
          this.result_mobile_items.splice(this.item.index, 1, params);
        }
      } else {
        if (this.type === 'desktop') {
          this.result_desktop_items.push(params);
        } else {
          this.result_mobile_items.push(params);
        }
      }

      this.$emit('updateList', 'desktop', this.result_desktop_items);
      this.$emit('updateList', 'mobile', this.result_mobile_items);

      this.show_modal = false;
    },
    addItem: function () {
      if (
        this.select_item === '' ||
        this.select_item === null ||
        this.select_item === undefined
      ) {
        alert('노출항목을 선택해주세요.');
        return false;
      }

      if (this.select_item !== '') {
        let isAdded = false;

        this.select_item_list.find((element, index) => {
          if (
            element.type === this.select_item.type &&
            element.title === this.select_item.title
          ) {
            isAdded = true;
          }
        });

        if (isAdded === true) {
          alert('이미 추가한 노출 항목입니다.');
          return false;
        } else {
          const product_index = this.product_list.findIndex(
            obj =>
              obj.product_no == this.select_product &&
              obj.product_result_item !== null,
          );
          if (product_index !== -1) {
            const json = JSON.parse(
              this.product_list[product_index].product_result_item,
            );
            const json_index = json.findIndex(
              obj =>
                obj.type === this.select_item.type &&
                obj.title === this.select_item.title,
            );
            if (json_index !== -1) {
              if (json[json_index].type === 'text') {
                this.select_item.content = json[json_index].text || '';
              } else if (json[json_index].type === 'image') {
                this.select_item.content = json[json_index].image || '';
              }
            }
          }
          this.select_item_list.push(this.select_item);
        }
      }
    },
    deleteItem: function (i) {
      this.select_item_list.splice(i, 1);
    },

    optionTextLength: function (text, length) {
      if (!length || length == 0) {
        length = 25;
      }
      if (text.length > length) {
        return text.substr(0, length) + '...';
      } else {
        return text;
      }
    },
    selectProductType: function () {
      this.select_product = '';
      this.rank = '';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.layer_dialog {
  max-width: 700px;
  min-width: 700px !important;
}

.btn.btn_light {
  background-color: #4b5563;
  color: #ffff;
}
</style>
